<template>
  <div class="download">
    <div class="banner">
      <div class="container">
        <div class="left-box">
          <strong>{{ $t(`Download['崭新生活，源于优付。']`) }}</strong>
          <div class="buttons">
            <a target="_blank" :href="appstoreUrl">
              <img
              width="30%"
                src="../assets/image/download/icon-Appstore@2x.png"
                alt=""
              /><span>App Store</span>
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.upay.paypoint"
            >
              <img
              width="30%"
                src="../assets/image/download/icon-Android@2x.png"
                alt=""
              /><span>Android</span>
            </a>
            <a
              target="_blank"
              href="https://appgallery.cloud.huawei.com/ag/n/app/C106934891?locale=en_US&source=appshare&subsource=C106934891&shareTo=com.android.bluetooth&shareFrom=appmarket&shareIds=426b202c97a8478fa681dd5fc751dc9d_com.android.bluetooth&callType=SHARE"
            >
              <img
                width="30%"
                src="../assets/image/download/huawei.png"
                alt=""
              /><span>Huawei</span>
            </a>
            <a
              target="_blank"
              href="http://api.u-pay.com/file/app.apk"
            >
              <img
                width="30%"
                src="../assets/image/download/apk.png"
                alt=""
              /><span style="text-align:center">APK <br>Download</span>
            </a>
          </div>
        </div>
        <img class="mobile-img" :src="rightImgUrl[$i18n.locale]" alt="" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Download',
  data() {
    return {
      appstoreUrl: /Safari/.test(window.navigator.userAgent) &&
                !/Chrome/.test(window.navigator.userAgent)
        ? 'itms-apps://itunes.apple.com/app/id1540481313'
        : 'https://apps.apple.com/cn/app/aillo/id1540481313',
      rightImgUrl: {
        en: require('../assets/image/download/DownloadEN.png'),
        zh: require('../assets/image/download/DownloadCN.png'),
        kh: require('../assets/image/download/DownloadKH.png')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.download {
  .banner {
    // background: url("../assets/image/download/banner@2x.png") no-repeat;
    background-image: linear-gradient(to bottom right, #ff3e47, #ff9459);
    background-size: cover;
    background-position: center;
    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 150px;
      color: #ffffff;
      .left-box {
        padding-top: 150px;
        strong {
          font-size: 36px;
          line-height: 1.3;
          display: inline-block;
          font-weight: bold;
          padding-right: 50px;
        }
        .buttons {
          margin: 50px 0px 0 -10px;
          display: flex;
          flex-wrap: wrap;
          a {
            display: inline-block;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 15px;
            margin-top: 10px;
            height: 56px;
            width: 150px;
            background: #ffffff;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            white-space: nowrap;
            margin: 0 10px 20px;
            img {
              margin-right: 7px;
            }
            span {
              font-size: 18px;
              color: #000000;
            }
          }
        }
      }
      .mobile-img {
        width: 340px;
        margin-bottom: 15px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .banner {
      .container {
        padding-top: 120px;
        .left-box {
          strong {
            font-size: 36px;
          }
        }
        .mobile-img {
          width: 240px;
        }
      }
    }
  }
  @media screen and (max-width: 895px) {
    .banner {
      .container {
        text-align: center;
        padding-top: 50px;
        .left-box {
          .buttons {
            justify-content: center;
          }
          strong {
            font-size: 28px;
          }
          .buttons {
            justify-content: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 786px) {
    .banner {
      .container {
        .left-box strong {
          padding-right: unset;
          width: 300px;
          font-size: 22px;
        }
        .mobile-img {
          width: 200px;
        }
      }
    }
  }
}
</style>
